<template>
    <ODialog v-model:show="showDialog" disableResize size="lg" :title="dlgTitle" :disableMovable="false" disableFade ref="nextStepDlg">
        <div class="o365-dialog-body d-flex flex-column overflow-x-hidden">
            <div class="row">
                <div v-if="dsItemsOrgUnitsLkp.data.length > 0" class="col-12 " >
                    <div class="border bg-white p-2">
                        <small style="font-weight: bold;margin-left: 6px;">{{$t("Set Action By Org Unit")}}</small>
                        <div v-for="row in dsItemsOrgUnitsLkp.data" class="form-check">
                            <label class="form-check-label" >
                                <input class="form-check-input"  :value="{OrgUnit_ID: row.OrgUnit_ID, OrgUnit: row.OrgUnit}" v-model="selectedOrgUnit" type="radio" name="orgunit" :disabled="isOrgUnitDisabled(row)">
                                {{ row.OrgUnit }} <span v-if="row.OrgUnit_ID === workflowDataObject.current.Originator_ID">({{ $t("Originator") }})</span>
                                                   <span v-if="row.OrgUnit_ID !== workflowDataObject.current.Originator_ID && dsItemsOrgUnitsLkp.data.length == 2">({{ $t("Other Party") }})</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="workflowDataObject.current.HasRespRoles_ID && !processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers"> {{$t("Select a responsible from an involved party")}}</div>
                <div class="col-6" v-if="workflowDataObject.current.HasRespRoles_ID && !processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers">
                    <ODataLookup 
                        :bind="onItemsOrgUnitsResp"
                        :dataObject="dsItemsOrgUnitsRespLkp"
                    >
                        <OColumn field="OrgUnit" width="350"></OColumn>
                        <OColumn field="Role" width="300"></OColumn>
                        <OColumn field="Name" width="200"></OColumn>
                        <OColumn field="Represents" width="220"></OColumn>
                        <template #target="{ target }">
                            <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc" :ref="target">{{$t("Responsible Involved Parties ")}}</button>
                        </template>
                    </ODataLookup>
                </div>

                <template v-if="!processStepDataObject.current.DisableFixToPerson">
                    <div class="mt-3 fw-bold"> {{$t("Select a person")}}</div>
                    <div class="col-sm-4 col-12" v-if="processStepDataObject.current.Role_ID || dsProcessStepRoles.data.length > 1">
                        <OPersonsLookup 
                            :dataObject="dsRoleMembersNS" 
                            :bind="onRoleMemberSelected"
                            :whereClause="roleMembersWhereClause"
                            reloadOnWhereClauseChange
                        >
                            <template #target="{ target }">
                                <button class="btn btn-sm btn-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRole" :ref="target">{{$t("Role Members")}}</button>
                            </template>
                        </OPersonsLookup>
                    </div>
                    <div class="col-sm-4 col-12">
                        <ODataLookup 
                            :bind="onPersonSelected"
                            disableRecent
                            :dataObject="dsPersonWitStepsLkp"
                            forceReloadOnOpen>
                            
                            <template #target="{ target }" >
                                <button class="btn btn-sm btn-primary mb-1 dropdown-toggle w-100" 
                                :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" :ref="target">{{$t("Earlier Done By")}}</button>
                            </template>
                            <OColumn field="Name" width="200" />
                            <OColumn field="Email" width="250" />
                            <OColumn field="Represents" width="250" />
                            <OColumn field="NumOfItems" class="text-center" headerName="Steps" width="80" />
                        </ODataLookup>
                    </div>
                    <div class="col-sm-4 col-12">
                        <OPersonsLookup 
                            v-if="!processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers"
                            :bind="onPersonSelected"
                            :dataObject="dsPersonLkp"
                            forceReloadOnOpen
                        >
                            <template #target="{ target }">
                                <button class="btn btn-sm btn-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" :ref="target">{{$t("All With Access")}}</button>
                            </template>
                            <template #toolbarActions v-if="!hideRestrictToPersonWithAccess">
                                <OToolbarAction :text="$t('Restrict To Persons With Access')" v-model="restrictPersonsLkpToPersonsWithAccess" />
                            </template>
                        </OPersonsLookup>
                        <OPersonsLookup 
                            v-else
                            :bind="onPersonSelected"
                            :dataObject="dsPersonsRTRTLkp"
                            :whereClause="personsRTRTLkpWhereClause"
                            forceReloadOnOpen>
                            <template #target="{ target }" >
                                <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" 
                                    :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" :ref="target">{{$t("All With Access 2")}}</button>
                            </template>
                        </OPersonsLookup>
                    </div>
                    <div class="col-6">
                        <button @click="meSelected" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" 
                        class="btn btn-sm btn-link mb-1">{{ $t("Me") }}: {{userSession.name}} </button>
                        <button @click="orgUnitSelected" v-if="isSubmittedItem" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole"
                            class=" btn btn-sm btn-link mb-1">{{$t("Creator")}}: {{ workflowDataObject.current.CreatedBy }}</button>
                    </div>
                </template>

                <template v-if="processStepDataObject.current.ForceFixedToPerson == false && changeResponsible == false && roleId !== null">  
                    <div class="fw-bold mt-3">{{$t("Send to members of role")}} </div>
                    <div v-for="row in dsProcessStepRoles.data" class="col-sm-4 col-12">
                        <button class="w-100 btn btn-sm btn-primary mb-1" :disabled="isExecutingProc" @click="onAllMembersSelected(row.Role_ID)">{{ row.Role }} </button>
                    </div>
                </template>
            </div>
            <div class="row" v-if="changeResponsible == true && props.stepRow.Person_ID">                        
                <div class="col-6 mt-2">
                    <button class=" btn btn-sm btn-outline-primary mb-1 w-100" @click="clearResponsible()" >{{$t("Clear Responsible") }}</button>
                </div>
            </div>
        </div>
        <!--</div>-->
    </ODialog>
</template>

<script setup>
import { getOrCreateProcedure, userSession } from 'o365-modules';
import { ref, defineExpose, computed, watch, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { ODialog } from 'o365-ui-components';
import { logger} from 'o365-utils';
import { OPersonsLookup } from 'o365-system-lookups';
import { Url } from 'o365-utils';

const emit = defineEmits(["submitted"]);

const props = defineProps({
    workflowDataObject: {
        type: Object,
        required: true
    },
    processStepDataObject: {
        type: Object,
        required: true
    },
    beforeNextStep: {
        type: Function,
        required: false
    },
    stepRow: {
        type: Object,
        required: false
    },
    itemStepId: {
        type: Number,
        required: false,
    },
    hideRestrictToPersonWithAccess : {
        type: Boolean,
        default: false
    }
});

const showDialog = ref(false);

defineExpose({ show });

// Data object definitions
const dsItemsOrgUnitsLkp = getOrCreateDataObject({
    id: 'dsItemsOrgUnitsLkp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsOrgUnitsAndSelf",
    maxRecords: -1,
    whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    selectFirstRowOnLoad: true,
    fields: [{ name: "OrgUnit", sortOrder: 1, sortDirection: "desc"},  { name: "OrgUnit_ID" }],
});

const dsRoleMembersNS = getOrCreateDataObject({
    id: 'dsRoleMembersNS' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsStepsPersonsRoleMembers",
    maxRecords: -1,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "ID" }, { name: "Name", sortOrder: 1, sortDirection: "asc"  }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsPersonLkp = getOrCreateDataObject({
    id: 'dsPersonLkp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_PersonsLookup",
    definitionProc:"astp_Scope_PersonsLookupRestrictedDefinition2",
    maxRecords: 25,
    loadRecents: false,
    distinctRows: false,
    fields: [{ name: "ID" }, { name: "Name", sortOrder: 1, sortDirection: "asc" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});


const dsPersonWitStepsLkp = getOrCreateDataObject({
    id: 'dsPersonWithStepsLkp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_PersonsLookup",
    definitionProc:"astp_Scope_PersonsLookupAllWithStepsCompletedDefinition",
    maxRecords: 25,
    loadRecents: false,
    distinctRows: false,
    fields: [{ name: "ID" }, { name: "Name" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }, { name: "NumOfItems" , sortOrder: 1, sortDirection: "desc" }],
});

const dsPersonsRTRTLkp = getOrCreateDataObject({
    id: "dsPersonsRTRTLkp" + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_PersonsLookupRTRTForProcessStep",
    maxRecords: 25,
    // whereClause: `ProcessStep_ID = ${props.processStepDataObject.current.ID} AND Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "ID" }, { name: "Name" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsItemsOrgUnitsRespLkp = getOrCreateDataObject({
    id: 'dsItemsOrgUnitsResp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsOrgUnitsResponsibles",
    maxRecords: 25,
    whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "OrgUnit" }, { name: "Name" }, { name: "ID" }, { name: "Role" }, { name: "Represents" }],
});

const dsProcessStepRoles = getOrCreateDataObject({
    id: 'dsProcessStepRoles' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ProcessesStepsRolesWithDefaultStepRole",
    maxRecords: -1,
    loadRecents: false,
    selectFirstRowOnLoad: true,
    distinctRows: true,
    fields: [{ name: "Role_ID" }, { name: "Role" }],
});

// Procedures
const procMoveStep = getOrCreateProcedure({ id: "procMoveStep", procedureName: "astp_Scope_ItemsStepsMove" });
const procInitiateWorkflow = getOrCreateProcedure({ id: "procInitiate", procedureName: "astp_Scope_ItemInitiate" });

// Refs
const nextStepDlg = ref(null);
const isExecutingProc = ref(false);
const role = ref(null);
const roleId = ref(null);
const orgUnit = ref(null);
const orgUnitId = ref(null);
const changeResponsible = ref(false);
const restrictPersonsLkpToPersonsWithAccess = ref(true);

// Computed values
const selectedOrgUnit = computed({
    get() { return {
        OrgUnit: orgUnit.value,
        OrgUnit_ID: orgUnitId.value
    }},
    set(pVal) {
        orgUnit.value = pVal.OrgUnit;
        orgUnitId.value = pVal.OrgUnit_ID;
    }
});
const isSubmittedItem = computed(() => !props.workflowDataObject.current.Hidden);
const restrictStepToRole = computed(() => props.workflowDataObject.current.RestrictToRole);
const restrictStepToRoleMembers = computed(() => props.processStepDataObject.current.RestrictToRoleMembers);
const roleMembersWhereClause = computed(() => `Role_ID = ${roleId.value} AND OrgUnit_ID = ${orgUnitId.value} AND ReadOnly = 0`);
const dlgTitle = computed(() => {
    if (props.workflowDataObject.current.ExecutionPathCode) {
        return `${props.processStepDataObject.current.Name} - ${$t("Execution Path")}: ${props.workflowDataObject.current.ExecutionPathCode}`;
    }

    return props.processStepDataObject.current.Name;
});

// Computed lookup where clauses
const personsRTRTLkpWhereClause = computed(() => `ProcessStep_ID = ${props.processStepDataObject.current.ID} AND Item_ID = ${props.workflowDataObject.current.ID}`);

async function show(pChangeResponsible) {
    if (pChangeResponsible) {
        changeResponsible.value = true;
    } else {
        changeResponsible.value = false;
    }
    nextStepDlg.value.showDialog();
        
    dsProcessStepRoles.recordSource.whereClause = "Step_ID = " + props.processStepDataObject.current.ID;
    dsProcessStepRoles.load();

    dsItemsOrgUnitsLkp.recordSource.whereClause = `Item_ID = ${props.workflowDataObject.current.ID}`;
    dsItemsOrgUnitsLkp.load().then(()=>{
        setDefaultOrgUnit();
    });

    dsItemsOrgUnitsRespLkp.recordSource.whereClause = `Item_ID = ${props.workflowDataObject.current.ID}`;
    dsItemsOrgUnitsRespLkp.load();

    roleId.value = props.processStepDataObject.current.Role_ID;    

    setDefaultRole();    
}

watch([() => props.workflowDataObject.current.ID, () => props.processStepDataObject.current.ID], () => {
    dsPersonWitStepsLkp.recordSource.sqlStatementParameters = {
        ProcessStep_ID: props.processStepDataObject.current.ID,
        OrgUnit_ID: props.workflowDataObject.current.OrgUnit_ID
    }

    dsPersonLkp.recordSource.sqlStatementParameters = {
        Item_ID: props.workflowDataObject.current.ID,
        AllPersons: false
    }
}, { immediate: true });

watch(restrictPersonsLkpToPersonsWithAccess, restrict => {
    if(restrict){
        dsPersonLkp.recordSource.definitionProc = 'astp_Scope_PersonsLookupRestrictedDefinition2'
    }else{
        dsPersonLkp.recordSource.definitionProc = 'astp_Scope_PersonsLookupAllDefinition'
    }
    
    if (dsPersonLkp.state.isLoaded) {
        dsPersonLkp.load();
    }
});

function setDefaultRole() {
    setRole(props.processStepDataObject.current);
}

function setDefaultOrgUnit() {
    orgUnitId.value = props.workflowDataObject.current.OrgUnit_ID;
    orgUnit.value = props.workflowDataObject.current.OrgUnit;

    if (props.processStepDataObject.current.OtherParty && dsItemsOrgUnitsLkp.data.length > 1){
        dsItemsOrgUnitsLkp.data.forEach(row => {
            if (row.OrgUnit_ID !== props.workflowDataObject.current.Originator_ID){
                orgUnitId.value = row.OrgUnit_ID;
                orgUnit.value = row.OrgUnit;
                return;
            }
        });
    }else if(props.processStepDataObject.current.ActionByOriginator && dsItemsOrgUnitsLkp.data.length > 1 ){
        dsItemsOrgUnitsLkp.data.forEach(row => {
            if (row.OrgUnit_ID === props.workflowDataObject.current.Originator_ID){
                orgUnitId.value = row.OrgUnit_ID;
                orgUnit.value = row.OrgUnit;
                return;
            }
        });
    }
}

function isOrgUnitDisabled(row){
    if(props.processStepDataObject.current.OtherParty){
        return dsItemsOrgUnitsLkp.data.length > 1 && row.OrgUnit_ID === props.workflowDataObject.current.Originator_ID;
    }else if(props.processStepDataObject.current.ActionByOriginator){
        return dsItemsOrgUnitsLkp.data.length > 1 && row.OrgUnit_ID !== props.workflowDataObject.current.Originator_ID;
    }
    return false;
}

function setRole(pRow) {
    roleId.value = pRow.Role_ID;
    role.value = pRow.Role;
}

function onAllMembersSelected(pRole_ID) {
    moveStep({ OrgUnit_ID: orgUnitId.value }, pRole_ID);
}

function onRoleMemberSelected(row) {
    moveStep({ Person_ID: row.ID });
}

function onPersonSelected(row) {
    moveStep({ Person_ID: row.ID });
}

function onItemsOrgUnitsResp(row){
    moveStep({ Person_ID: row.ID });
}

function meSelected() {
    if (isSubmittedItem.value) {
        moveStep({ SetMeAsResp: true });
    } else {
        moveStep({ Person_ID: userSession.personId });
    }
}

function orgUnitSelected() {
    moveStep({ Person_ID: props.workflowDataObject.current.CreatedBy_ID });
}

async function moveStep(params, pRole_ID) {
    await props.stepRow?.save();
    await props.workflowDataObject.save();
    if (props.beforeNextStep) {
        try {
            await props.beforeNextStep();
        } catch (ex) {
            logger.error(ex);
        }
    }
    if (changeResponsible.value == false) {
        const isExistingItem = !props.workflowDataObject.current.Hidden;
        const procToBeExecuted = isExistingItem ? procMoveStep : procInitiateWorkflow;
        const defaultParams = { 
            ProcessStep_ID: props.processStepDataObject.current.ID,
            OrgUnit_ID: orgUnitId.value ? orgUnitId.value : props.workflowDataObject.current.OrgUnit_ID,
            Role_ID: pRole_ID ? pRole_ID : roleId.value,
            ...isExistingItem ? {
                // Move existing Item params
                Item_ID: props.workflowDataObject.current.ID,
                ItemStep_ID: props.itemStepId ?? props.workflowDataObject.current.Step_ID ?? props.workflowDataObject.current.CurrentStep_ID,
                ReOpenComment: $t("Workflow reopened by") + " " + userSession.name
            } : { 
                // Initiate new Item params
                ID: props.workflowDataObject.current.ID,
                ProcessStep_ID: props.processStepDataObject.current.ID,
                OrgUnit_ID: orgUnitId.value ? orgUnitId.value : props.workflowDataObject.current.OrgUnit_ID,
                AgendaItemUrl_ID: Url.getParam('AgendaItemUrl_ID'),
                AgendaItem_ID: Url.getParam('AgendaItem_ID')
            }
        };
        /*if(!props.workflowDataObject.current.Hidden){
            props.workflowDataObject.current.OrgUnit_ID = orgUnitId.value ? orgUnitId.value : props.workflowDataObject.current.OrgUnit_ID;
            props.workflowDataObject.current.OrgUnit =  orgUnit.value ? orgUnit.value : props.workflowDataObject.current.OrgUnit;
            props.workflowDataObject.save();
        }*/

        isExecutingProc.value = true;
        return procToBeExecuted.execute({ ...defaultParams, ...params }).then((response)=>{            
            console.log('after',orgUnitId.value ? orgUnitId.value : props.workflowDataObject.current.OrgUnit_ID)
            console.log('after',orgUnit.value ? orgUnit.value : props.workflowDataObject.current.OrgUnit)

            //props.workflowDataObject.save();
            onSubmitted(response);
        }).finally(() => (
            isExecutingProc.value = false
            
        ));
    } else {
        if (params.SetMeAsResp) {
            props.stepRow.Person_ID = userSession.personId;
        } else {
            props.stepRow.Person_ID = params.Person_ID;
        }
        await props.stepRow.save();
        nextStepDlg.value.hideDialog();
    }
}

async function clearResponsible(){
    props.stepRow.Person_ID = null;
    await props.stepRow.save();
    nextStepDlg.value.hideDialog();
}

function onSubmitted(procResponse) {
    nextStepDlg.value.hideDialog();
    emit("submitted", procResponse);
}

</script>